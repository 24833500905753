.l-table-wrapper {
    margin-top: 5rem;
}

.leistung {
    padding: 2rem;
    box-shadow: 4px 4px 8px -2px #2e4766;
    height: 100%;
}

.leistung p {
    margin-top: 1.75rem;
}