@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  color: #555a61;
  font-size: 2.2em;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
}

h2 {
  color: #555a61;
  text-align: center;
  font-size: 1.7em;
  font-family: 'Open Sans', sans-serif;
}

h3 {
  color: #2e4766;
  font-family: 'Open Sans', sans-serif;
}

section {
  margin: 4em 0;
}

body {
  font-family: 'Open Sans', sans-serif !important;
}

.nav-item {
  font-size: 1.3rem;
}

a {
  color: #2e4766 !important;
  text-decoration: none;
}

a:hover {
  color: #2e4766 !important;
  text-decoration: underline;
}
p.intro {
    text-align: center;
    font-size: 1.8rem;
    font-style: italic;
}

section.behind {
    background-color: #f3f3f3;
    box-shadow: inset 0 0 20px 0 #c2c2c2;
}

.img-baugrund {
    padding: 2rem 0;
    max-height: 550px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.expose {
    margin: 4rem 0;
    padding-bottom: 5rem;
    border-bottom: 1px solid #c2c2c2;
}

.expose:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.expose img {
    display: block;
    margin: 3.5rem auto;
    max-width: 70%;
}

.expose p {
    text-align: center;
    margin-bottom: 2rem;
}

.expose a {
    display: block;
    text-align: center;
    font-size: 1.7rem;
    text-decoration: none;
    font-weight: bold;
    font-style: italic;
}

.expose .sold {
    text-decoration: line-through;
}

.expose a:hover {
    text-decoration: underline;
}

.contact {
    width: 310px;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .contact {
        margin-bottom: 0;
    }

    .expose a {
        float: right;
        font-size: 1.7rem;
    }
}


header {
    display: flex !important;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

header .logo {
    height: calc(100vh - 63px);
    width: 100vw;
}

header .intro-text {
    padding: 3rem;
    text-align: center;
    font-size: 1.8rem;
    font-style: italic;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    background-color: #e7e7e7;
    margin: 0;
    box-sizing: border-box;
}
.l-table-wrapper {
    margin-top: 5rem;
}

.leistung {
    padding: 2rem;
    box-shadow: 4px 4px 8px -2px #2e4766;
    height: 100%;
}

.leistung p {
    margin-top: 1.75rem;
}
h1 {
    margin-bottom: 1rem !important;
}

p.address {
    margin-top: 3rem;
    text-align: center;
}

.address a {
    color: #2e4766;
    text-decoration: none;
}

.address a:hover {
    color: #2e4766;
    text-decoration: underline;
}

.logo-irchenhauser {
    width: 100%;
}

.logo-wenger {
    display: block;
    margin: 0 auto;
}
.l-content-wrapper {
    margin-top: 4rem;
}

.card {
    margin-bottom: 2rem;
}

.carousel-image {
    height: 400px;
    margin: 0 auto;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232e4766' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232e4766' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators .active {
    background-color: #2e4766;
}
footer {
    display: block;
    background-color: #2e4766;
    text-align: center;
    color: #f3f3f3;
    line-height: 1.5rem;
    padding: 2rem;
}

footer a {
    font-weight: bold;
    color: #ffffff !important;
}

.insta-link {
    vertical-align: middle;
}

footer a:hover {
    color: #ffffff !important;
}
