h1 {
    margin-bottom: 1rem !important;
}

p.address {
    margin-top: 3rem;
    text-align: center;
}

.address a {
    color: #2e4766;
    text-decoration: none;
}

.address a:hover {
    color: #2e4766;
    text-decoration: underline;
}

.logo-irchenhauser {
    width: 100%;
}

.logo-wenger {
    display: block;
    margin: 0 auto;
}