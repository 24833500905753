header {
    display: flex !important;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

header .logo {
    height: calc(100vh - 63px);
    width: 100vw;
}

header .intro-text {
    padding: 3rem;
    text-align: center;
    font-size: 1.8rem;
    font-style: italic;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    background-color: #e7e7e7;
    margin: 0;
    box-sizing: border-box;
}