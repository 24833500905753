footer {
    display: block;
    background-color: #2e4766;
    text-align: center;
    color: #f3f3f3;
    line-height: 1.5rem;
    padding: 2rem;
}

footer a {
    font-weight: bold;
    color: #ffffff !important;
}

.insta-link {
    vertical-align: middle;
}

footer a:hover {
    color: #ffffff !important;
}