p.intro {
    text-align: center;
    font-size: 1.8rem;
    font-style: italic;
}

section.behind {
    background-color: #f3f3f3;
    box-shadow: inset 0 0 20px 0 #c2c2c2;
}

.img-baugrund {
    padding: 2rem 0;
    max-height: 550px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

.expose {
    margin: 4rem 0;
    padding-bottom: 5rem;
    border-bottom: 1px solid #c2c2c2;
}

.expose:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.expose img {
    display: block;
    margin: 3.5rem auto;
    max-width: 70%;
}

.expose p {
    text-align: center;
    margin-bottom: 2rem;
}

.expose a {
    display: block;
    text-align: center;
    font-size: 1.7rem;
    text-decoration: none;
    font-weight: bold;
    font-style: italic;
}

.expose .sold {
    text-decoration: line-through;
}

.expose a:hover {
    text-decoration: underline;
}

.contact {
    width: 310px;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .contact {
        margin-bottom: 0;
    }

    .expose a {
        float: right;
        font-size: 1.7rem;
    }
}

