@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&display=swap');

body {
  font-family: 'Open Sans', sans-serif !important;
}

.nav-item {
  font-size: 1.3rem;
}

a {
  color: #2e4766 !important;
  text-decoration: none;
}

a:hover {
  color: #2e4766 !important;
  text-decoration: underline;
}